<template>
    <div class="row w-100">
        <div class="w-100" v-if="isLoadedUsers">
        <table class="tab table b-table text-center mx-3" role="table" v-if="room_users">
            <thead>
            <tr>
                <th scope="col" class="text-left">{{$t('koth.room.user')}}</th>
                <th scope="col">{{$t('koth.room.country')}}</th>
                <th scope="col">{{$t('koth.room.correct_flags')}}</th>
                <th scope="col">{{$t('koth.room.king_time')}}(min)</th>
                <th scope="col">{{$t('koth.room.points')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="data in room_users_sorted" v-bind:key="data.id">

                <td :data-label="$t('koth.room.user')" class="text-left">
                    <b-avatar :src="data.user.avatar" class="mr-3"></b-avatar>
                    <router-link :to="{name:'ProfilUser',params:{id:data.user_id}}">{{data.user.username}}</router-link>
                </td>
                <td :data-label="$t('koth.room.country')">{{data.user.country?data.user.country:'-'}}</td>
                <td :data-label="$t('koth.room.correct_flags')">{{data.solves_count}}</td>
                <td :data-label="$t('koth.room.king_time')">{{data.king_time?data.king_time:0}}</td>
                <td :data-label="$t('koth.room.points')">{{data.score?data.score:0}}</td>
            </tr>
            </tbody>
        </table>
        <p v-else  class="text-center my-2">
            {{$t('validation.no_scoreboard')}}
        </p>
        </div>
        <div v-else class="w-100 mx-3">
            <b-skeleton-table :rows="5" :columns="5"></b-skeleton-table>
            <b-skeleton></b-skeleton>
        </div>
    </div>
</template>

<script>
    export default {
        name: "scoreboardKoth",
        props:['room_users_p'],
        watch: {
        room_users_p: {
            immediate: true,
            handler ()
            {
                if(this.room_users_p)
                {
                    if(this.room_users_p.length>1) this.room_users = this.room_users_p;

                }
            }
        }
        },
        data(){
            return{
                isLoadedUsers:false,
                room_users:this.room_users_p,

            }
        },
        created(){
            this.getScoreboard()
        },
      computed:{
        room_users_sorted:function() {
         let users =  this.room_users;
          return   users.sort((a, b) => b.score - a.score);
        }
      },
        methods:{
            getScoreboard() {
                this.$koth
                    .get('rooms/' + this.$route.params.id+'/scoreboard')
                    .then(response => {
                        this.room_users = response.data.map( p => ({ ...p,score:p.score?parseInt(p.score):0}));
                        this.$emit('set-scoreboard',this.room_users)
                        this.isLoadedUsers = true
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
        }
    }
</script>

<style scoped>

</style>