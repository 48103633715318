<template>
    <b-modal id="room-code" centered :title="$t('koth.join.code')" title-class="font-18"
             hide-footer @shown="bani">
                                    <b-card-text>
                                        <form>
                                            <div class="form-group  mb-0">
                                                <label>{{$t('profil_team.code')}} :</label>
                                                <div class="d-flex">

                                                <b-form-input v-model="code_room" type="text" disabled></b-form-input>
                                                <i class="fas fa-paste mt-2 ml-2 fa-2x" v-b-tooltip.hover.top :title="title"
                                                   v-clipboard:copy="code_room"
                                                   v-clipboard:success="onCopy" alt="Copy to clipboard"></i>
                                                </div>
                                            </div>

                                        </form>
                                    </b-card-text>
    </b-modal>
</template>

<script>
    export default {
        name: "roomCode",
        props:['code'],
        data(){
            return{
                title:'Copy',
                code_room:''
            }
        },

        methods: {
            bani(){
                this.code_room = this.code
            },
            onCopy: function () {
              setTimeout(() => {
                this.title = 'Copied';
              }, 200);
              this.title = 'Copy';

            },
             generate(){
                this.$koth
                    .get('rooms/'+this.$route.params.id+'/generate')
                    .then(response => {
                        this.code_room = response.data.code;
                    })
                    .catch(error => {
                        console.log(error)

                    })



            }

        }
    }
</script>

<style scoped>

</style>