<template>
  <div id="chart" style="text-decoration:none" >
    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: "userEvolutionKoth",
  props:['evolution_p'],
  watch: {
        "evolution_p.score_evolution": function(){
             this.parseEvolutionData(this.evolution_p);
        }
        },

  data(){
    return{

      series: [],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        colors: ['#556EE6','#ECBD34','#34c38f'],
        labels:[],
        title: {
          text: 'User Evolution',
          align: 'left'
        },
        noData: {
          text: 'Loading...'
        },
        xaxis: {
          type: 'datetime',

          labels: {
            show: true,
            format: 'MMM dd HH:mm',

          },
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        }
      }



    }
  },
  created(){
    this.getEvolution()
  },
  methods:{

    parseEvolutionData(evolution) {

                  let users_name = evolution.player_name;
                  let solves = evolution.score_evolution;
                  let users = {};
                  let label_x = [evolution.begin_room];
                  var i,j;
                  for (i=0; i<users_name.length; i++)
                  {
                    users[users_name[i]] = [0];
                  }

                  for (i=0 ; i<solves.length; i++)
                  {

                    label_x.push(solves[i].created_at);
                    let value  = solves[i].value;
                    let user_solvator = solves[i].user.username;
                    for (j=0; j<users_name.length; j++)
                    {
                      if(user_solvator === users_name[j])   // If We Are in the Team that solved The Challenge
                      {
                        users[users_name[j]].push(users[users_name[j]].slice(-1)[0]+value);
                      }
                      else // If We are in a Team that not solved the Challenge
                      {
                        users[users_name[j]].push(users[users_name[j]].slice(-1)[0]);
                      }
                    }

                  }
                  let users_score = Object.keys(users);
                  for(i=0;i<users_score.length;i++)
                  {

                    this.$set(this.series, i, {name:users_score[i] ,data: users[[users_score[i]]]});
                  }
                  this.chartOptions = {...this.chartOptions, ...{
                      labels: label_x,
                    }}
                 this.$forceUpdate()
    },
    getEvolution(){
      this.$koth
              .get('rooms/' + this.$route.params.id+'/evolution')
              .then(response => {
                this.$emit('set-evolution',response.data)
                this.parseEvolutionData(response.data)
              })
              .catch(error => {
                console.log(error)
              })
    }
  }
}
</script>

<style scoped>
/deep/ .apexcharts-legend-text,/deep/ .apexcharts-texts{
  color: #fff!important;
}
/deep/ text{
  fill: #fff;
}
#chart{
  text-decoration: none;
}

</style>
