<template>
  <main>
    <div class="row m-0 p-2">
      <div class="col-xl-6 page-title-box d-flex">
        <i class="fa fa-arrow-left fa-2x my-auto mr-4 yellow" @click="$router.push({ name: 'Koth'})"></i>
        <h3 class="pt-2 text-center">
          {{ $t('koth.room.t') }} <span class="yellow mx-2"> # {{ room.id }}</span>
        </h3>
        <span class="text-center ml-3"
              v-bind:class="{'gold':room.status!='Insufficient Players',
                'insufficient_players':room.status == 'Insufficient Players'}"


        >{{ room.status }}</span>
      </div>
      <div class="col-xl-3 ml-auto text-right" v-if="!['Finished','Insufficient Players'].includes(room.status)">
        <button class="btn btn-primary mr-3" v-if="room.type=='private'" v-b-modal.room-code>
          {{ $t('koth.room.code') }}
        </button>
        <room-code :code="room.code" v-if="room.type=='private'"/>
        <b-button variant="danger"  v-if="room.is_joined && !['Finished','Insufficient Players'].includes(room.status)" v-b-modal.unjoin>{{ $t('koth.room.leave') }}</b-button>
        <b-button variant="success" v-if="room.is_joined == 0 && room.type=='public'" @click="join()">{{ $t('koth.room.join') }}
        </b-button>
        <unjoin-confirmation :id="$route.params.id" type="rooms"/>
      </div>
    </div>


    <div class="row m-0 p-2 flex-sm-row-reverse">
      <div class="col-xl-3  ml-auto ">
        <div class="pb-4 text-center border" style="border-radius: 12px;height: fit-content">
          <div v-if="!load">
            <b-skeleton class="my-3 mx-auto" width="60%"></b-skeleton>
            <b-skeleton class="mb-2 mx-auto" width="60%"></b-skeleton>
          </div>
          <div class="row mb-2 mx-0">
            <b-badge  class="p-2 ml-auto"
                     :class="{'bg-primary':room.type == 'public','bg-danger':room.type == 'private'}" v-if="room.type">
              <i class="fas fa-lock text-white mr-2"></i>{{ room.type }}
            </b-badge>
          </div>
          <h4 v-if="room.ip && room.machine">{{ room.machine.name }}</h4>
          <h4 v-if="!room.ip"> --- </h4>
          <h4 v-if="room.ip"><span v-if="!room.is_finished">{{ room.ip }}</span></h4>
          <h4 v-else-if="room.is_joined || room.status == 'Insufficient Players'">XX.XX.XX.XX</h4>

          <button class="btn btn-primary px-3 my-3" @click="resetMachine"
                  v-if="room.status=='Running' && room.is_joined && !room.is_reseted"
                  :disabled="room.is_voted==1">{{ $t('koth.room.reset') }}
            ({{ room.votes }}/{{ Math.ceil(room.users_count / 2)+1 }})
          </button>
          <h6 class="my-3 text-muted" v-if="room.status=='Running' ">{{ $t('koth.room.finish_in') }}</h6>
          <h6 class="my-3 text-muted" v-if="room.status=='Pending'">{{ $t('koth.room.start_in') }}</h6>
          <div id="timer" class="timer mb-2" v-if="room.pass_time!=0">
            <!--  Timer Component  -->
            <TimerRoom
                :passTime_p="parseInt(room.pass_time)"
                :room_status="room.status"
                v-on:end-time="EndTime"
            ></TimerRoom>
            <!--  End! Timer Component  -->
          </div>
          <h6 class="my-3">{{ $t('koth.room.king_changes') }}: {{ room.king_changes }}</h6>
        </div>

        <div class="text-center">
          <div class="mb-2 mt-4">
            <b-avatar v-if="room.winner" class=" user-avatar-shadow king-avatar mt-0"
                      :src="room.winner.avatar"></b-avatar>
            <b-avatar v-else-if="room.current_king" class=" user-avatar-shadow king-avatar mt-0"
                      :src="room.current_king.avatar"></b-avatar>
            <div class="badge badge-pill badge-secondary king-badge" v-if="room.winner">Winner</div>
            <div class="badge badge-pill badge-secondary king-badge" v-else-if="room.current_king">King</div>

          </div>
          <h4 class="mb-2" v-if="room.winner">{{ room.winner.username }}</h4>
          <h4 class="mb-2" v-else-if="room.current_king">{{ room.current_king.username }}</h4>

        </div>
        <hacktivity-koth class="w-100 my-3" :notifications_p="hacktivities"/>
      </div>
      <div class="col-xl-9 d-none d-xl-block">
        <user-evolution-koth class="mb-4" :evolution_p="evolution" v-on:set-evolution="setEvolution"></user-evolution-koth>
        <div class="row mb-4 ml-xl-4" v-if="room.status =='Running' & room.is_joined">
          <b-input-group class="col-xl-11 p-0">
            <template #prepend style="height: 35px">
              <b-input-group-text class="px-4">
                <i class="fas fa-flag"></i>
              </b-input-group-text>

            </template>
            <b-form-input

                type="text" class="form-control"
                placeholder="CRISI5{THIS_IS_FLAG}"
                v-model="submit_flag" @keyup.enter="submit"
                :class="{ 'is-invalid': typesubmit && $v.submit_flag.$error }">
            </b-form-input>
            <div v-if="typesubmit && $v.submit_flag.$error"
                 class="invalid-feedback">
              <span v-if="!$v.submit_flag.required"> {{ $t('validation.required') }}</span><br
                v-if="!$v.submit_flag.required"/>
              <span v-if="!$v.submit_flag.valid">{{ $t('validation.invalid_flag') }}</span>
            </div>
          </b-input-group>
          <div class="col-xl-1 text-right p-0">
            <button class="btn btn-primary submit w-100 " style="height: 35px"
                    @click="submit">
              {{ $t('competition.submit') }}
            </button>
          </div>
        </div>
        <div class="row mb-4 ml-xl-4 ">

          <scoreboard-koth :room_users_p="room_users" v-on:set-scoreboard="setScoreBoard"/>
        </div>
      </div>


    </div>


  </main>
</template>

<script>

import RoomCode from "../../components/koth/roomCode";
import {required} from "vuelidate/lib/validators";
import UserEvolutionKoth from "../../components/koth/userEvolutionKoth";

import TimerRoom from "../../components/koth/TimerRoom";
import UnjoinConfirmation from "../../components/popups/unjoinconfirmation";
import HacktivityKoth from "../../components/koth/HacktivityKoth";
import Swal from "sweetalert2";
import ScoreboardKoth from "../../components/koth/scoreboardKoth";
// import Vue from "vue";

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  showConfirmButton: false,
  timer: 2000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export default {
  name: "KothRoom",
  components: {
    ScoreboardKoth,
    HacktivityKoth,
    UnjoinConfirmation,
    TimerRoom,
    UserEvolutionKoth,
    RoomCode
  },
  data() {
    return {
      hacktivities: [],
      room: {machine: {}, pass_time: 0},
      submit_flag: '',
      type: '',
      typesubmit: false,
      load: false,
      room_users: [],
      evolution: {
        player_name:[],
        score_evolution:[]
      },
    }

  },
  validations: {
    submit_flag: {
      required,
      valid: function (value) {
        return /^CRISIS{.*}$/.test(value)
      }
    },
  },
  created() {
    this.getRoom()

  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.pusher_koth.unsubscribe('private-rooms_' + this.type + '_' + this.$route.params.id);
    if (this.type == 'public' && this.room.is_joined) this.$store.state.pusher_koth.unsubscribe('private-rooms_public_' + this.$route.params.id + '_users');
    next()
  },
  computed: {
    is_joined: function () {
      return this.room.is_joined
    },
  },
  watch: {
    is_joined: function () {
      if (this.type == 'public' && this.is_joined) {
        var room_users_channel = this.$store.state.pusher_koth.subscribe('private-rooms_public_' + this.$route.params.id + '_users');

        room_users_channel.bind('machine_reseted', (data) => {
          console.log("machine_reseted", data);
          this.room.votes += 1;
          this.room.ip = "";
          this.room.current_king = ""
          this.hacktivities.unshift(data)
          Toast.fire({icon: 'success', title: 'Machine Reseted !'})
        });
        room_users_channel.bind('votes_updated', (data) => {
          console.log("votes_updated", data);
          this.room.votes = data.votes_count;
          Toast.fire({icon: 'success', title: 'Vote Updated !'})
        });
        room_users_channel.bind('ip_generated', (data) => {
          console.log("ip_generated", data);
          this.room.ip = data.ip
          // this.room.machine = data.machine
          this.room.status = data.status
          this.room.pass_time = data.pass_time;
          this.$forceUpdate()
          Toast.fire({icon: 'success', title: 'Machine Ip :'+data.ip})
        });

      }
    },
    type: function () {
      var room_channel = this.$store.state.pusher_koth.subscribe('private-rooms_' + this.type + '_' + this.$route.params.id);
      // Vue.prototype.$http.defaults.headers.common['X-Socket-Id'] = socketId;
      room_channel.bind('user_joined', (data) => {
        console.log("user_joined : ", data);
        let new_user = {
          'user': data.user,
          'user_id': data.user.user_id,
          'solves_count': 0,
          'king_time': 0,
          'score': 0
        }
        this.room_users.push(new_user)
        this.evolution.player_name.push(data.user.username)
        this.room.users_count += 1
        this.hacktivities.unshift(data.hacktivity)
        this.$forceUpdate()


      });
      room_channel.bind('user_unjoined', (data) => {
        console.log("user_unjoined : ", data);
        let index = this.room_users.findIndex(item => item.user_id === data.user_id);
        if (index != -1) this.room_users.splice(index, 1);
        this.room.users_count -= 1
        this.hacktivities.unshift(data.hacktivity)
        this.$forceUpdate()

      });

      room_channel.bind('end_room', (data) => {
        console.log("end room : ", data);
        if(data.is_winner) this.room.winner = data.user
        this.room.status = 'Finished'
        this.room.is_finished = true
        this.$forceUpdate()
      });
      room_channel.bind('insufficient_players', (data) => {
        console.log("insufficient players: ",data);
        this.room.ip = ''
        this.room.status = 'Insufficient Players'
        this.$forceUpdate()
      });

      room_channel.bind('scoreboard_updated', (data) => {
         console.log("scoreboard_updated", data);
          let index = this.room_users.findIndex(item => item.user_id === data.scoreboard.user_id);
          if (data.room.current_king) {
            let index_ck = this.room_users.findIndex(item => item.user_id === data.room.current_king);
            this.room.current_king = this.room_users[index_ck].user
          }
          if (!data.hacktivity || (data.hacktivity && data.hacktivity.type !== 'Correct Flag Submission')) this.room_users[index].king_time += 1;
          if (this.room.king_changes !== data.room.king_changes) this.room.king_changes = data.room.king_changes
          // if(this.$store.state.user.user_id!==data.scoreboard.user_id) {
            this.room_users[index].score = parseInt(this.room_users[index].score) + parseInt(data.scoreboard.value);
            if (data.hacktivity) {
            this.hacktivities.unshift(data.hacktivity)
            if (data.hacktivity.type === 'Correct Flag Submission') this.room_users[index].solves_count += 1;
             }
          let solves = data.scoreboard
          solves.user = this.room_users[index].user
          this.evolution.score_evolution.push(solves)
       // }
          this.$forceUpdate()
      });
      if (this.type == 'private') {
        room_channel.bind('machine_reseted', (data) => {
          console.log("machine_reseted", data);
          this.room.votes += 1;
          this.room.ip = "";
          this.room.current_king = ""
          this.hacktivities.unshift(data)
          Toast.fire({icon: 'success', title: 'Machine Reseted !'})
        });
        room_channel.bind('votes_updated', (data) => {
          console.log("votes_updated", data);
          this.room.votes = data.votes_count;
          Toast.fire({icon: 'success', title: 'Vote Updated !'})
        });
        room_channel.bind('ip_generated', (data) => {
          console.log("ip_generated", data);
          this.room.ip = data.ip
          // this.room.machine = data.machine
          this.room.status = data.status
          this.room.pass_time = data.pass_time;
          this.$forceUpdate()
          Toast.fire({icon: 'success', title: 'Machine Ip : '+data.ip})

        });
      }

    }
  },
  methods: {
    EndTime() {
      console.log("end time")
      this.room.pass_time = this.room.duration*60
      if (this.room.users_count == 1) this.$router.push({name: 'Koth'})
    },
    setScoreBoard(data) {
      this.room_users = data
      this.$forceUpdate()
    },
    setEvolution(data){
      this.evolution = data
      this.$forceUpdate()
    },
    join() {
      this.$koth
          .post('rooms/' + this.$route.params.id + '/join',{},{
            headers: {
              'X-Socket-Id': this.$store.state.pusher_koth.connection.socket_id
            }
          })
          .then(response => {
            console.log(response)
            this.room.is_joined = true;
            this.room.users_count += 1;
            let new_user = {
              'user': response.data.user,
              'solves_count': 0,
              'king_time': 0,
              'score': 0
            }
            this.room_users.push(new_user)
            this.hacktivities.unshift(response.data.hacktivity)
            this.$forceUpdate()
            Toast.fire({icon: 'success', title: 'Success'})
          })
          .catch(error => {
            console.log(error)
            Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
          })
    },
    getRoom() {
      this.$koth
          .get('rooms/' + this.$route.params.id)
          .then(response => {
            this.room = response.data
            this.room.winner =  (this.room.solves.length && !this.room.pass_time)?  this.room.solves[0] : null
            this.type = this.room.type
            this.load = true
            this.$forceUpdate()
          })
          .catch(error => {
            if (error.response) {
              Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
              if(error.response.status == 403) this.$router.push({name:'Koth'})
            }
          })
    },
    resetMachine() {

      this.$koth
          .post('rooms/' + this.$route.params.id + '/reset',{},{
            headers: {
              'X-Socket-Id': this.$store.state.pusher_koth.connection.socket_id
            }
          })
          .then(response => {
            console.log(response.data)
            this.room.is_voted = true
            this.room.votes+=1
          })
          .catch(error => {
            console.log(error)
            Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})

          })
    },
    submit() {
      this.typesubmit = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {

        return;

      }
      this.submitFlag()
    },
    submitFlag() {
      this.$koth
          .post('rooms/' + this.$route.params.id + '/submit',
              {'flag': this.submit_flag},
              {
                headers: {
                  'X-Socket-Id': this.$store.state.pusher_koth.connection.socket_id
                }
              })
          .then(response => {
            console.log(response.data)
            Toast.fire({icon: 'success', title: 'Success'})
            this.submit_flag = ''
            this.typesubmit = false
            this.hacktivities.unshift(response.data.hacktivity)
            let index = this.room_users.findIndex(item => item.user_id === response.data.scoreboard.user_id);
            this.room_users[index].score = parseInt(this.room_users[index].score)+parseInt(response.data.scoreboard.value);
            this.room_users[index].solves_count += 1;
            let solves = response.data.scoreboard
            solves.user = this.room_users[index].user
            this.evolution.score_evolution.push(solves)
          })
          .catch(function (error) {
            console.log(error);

            Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})

          })


    },


  }

}
</script>

<style scoped>


.reset {
  width: 150px;
  background-color: #556EE6;
  margin-left: 20px;
  text-align: center
}


.yellow {
  color: #ECBD34;
  cursor: pointer;
}

.gold {
  background-color: #ECBD34;
  color: #2A2E51;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  height: 42px;
}

.insufficient_players {
  background-color: #d10408;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  height: 42px;
}

.submit:hover {
  background-color: #34c38f;
  color: white;
}

.user-avatar-shadow {
  display: block;
  width: 9em;
  height: 9em;
  margin: 1em auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  border: 6px solid #ECBD34;
  background-color: #ECBD34;
  box-shadow: inset 0px 0px 3px 3px rgba(0, 0, 0, 0.4);
  object-fit: cover;
}

.king-avatar {
  width: 7em;
  height: 7em;
  border: 5px solid #ECBD34;
  /* margin: 1em; */
  margin-bottom: 0;
}

.king-badge {
  position: relative;
  background-color: #ECBD34;
  top: -19px;
  color: #2A2E51;
  font-size: 16px;
}
</style>
