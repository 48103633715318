var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title text-center mb-3"},[_vm._v(_vm._s(_vm.$t('koth.hacktivity')))]),(_vm.isload)?_c('b-card-text',[(_vm.notifications.length)?_c('simplebar',{staticStyle:{"max-height":"240px","padding-right":"12px","padding-left":"12px"}},[_c('ul',{staticClass:"list-unstyled chat-list"},_vm._l((_vm.notifications),function(data){return _c('li',{key:data.id},[_c('hr'),_c('div',{staticClass:"media mb-2"},[_c('div',{staticClass:"media-left mr-2 my-auto"},[_c('i',{staticClass:"fas fa-2x",class:{
                                     'fa-chess-king king':data.type=='King Change',
                                     'fa-flag text-white':data.type=='Correct Flag Submission',
                                     'fa-laptop-code join':data.type=='Join Room',
                                     'fa-laptop-code text-white':data.type=='Machine Reset',
                                     'fa-laptop-code text-danger':data.type=='Leave Room',
                                  }})]),_c('div',{staticClass:"media-body overflow-hidden"},[_c('div',[_c('span',{staticClass:"font-size-11 float-right"},[_vm._v(_vm._s(data.time_diff))]),_c('h5',{staticClass:"font-size-14 mb-1"},[_vm._v(_vm._s(data.type))])]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(data.message))])])])])}),0)]):_c('span',{staticClass:"text-muted row"},[_c('p',{staticClass:"m-auto"},[_vm._v(_vm._s(_vm.$t('validation.no_data')))])])],1):_c('b-card-text',[_c('simplebar',{staticStyle:{"max-height":"240px","padding-right":"12px","padding-left":"12px"}},[_c('ul',{staticClass:"list-unstyled chat-list"},_vm._l((4),function(index){return _c('li',{key:index},[_c('hr'),_c('div',{staticClass:"media mb-2"},[_c('div',{staticClass:"media-left mr-2 my-auto"},[_c('b-avatar')],1),_c('div',{staticClass:"media-body overflow-hidden"},[_c('h5',{staticClass:"font-size-14 mb-1"},[_c('b-skeleton',{staticClass:"mb-2",attrs:{"width":"50%"}})],1),_c('p',{staticClass:"mb-0"},[_c('b-skeleton',{staticClass:"mb-2",attrs:{"width":"100%"}}),_c('b-skeleton',{staticClass:"mb-2",attrs:{"width":"70%"}})],1)]),_c('div',{staticClass:"font-size-11"},[_c('b-skeleton',{staticClass:"mb-2",attrs:{"width":"20%"}})],1)])])}),0)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }