<template>
    <div>
        <div class="my-auto" v-if="!text">
          <div class="hour" v-if="hours>0">
            <span class="number" v-if="room_status == 'Insufficient Players'"> - </span>
            <span class="number" v-else>{{ hours }}</span>
          </div>
          <div class="nbr" v-if="hours>0">
            <span class="number">:</span>
          </div>
            <div class="min">
                <span class="number" v-if="room_status == 'Insufficient Players'"> - </span>
                <span class="number" v-else>{{ minutes }}</span>
            </div>
            <div class="nbr">
                <span class="number">:</span>
            </div>
            <div class="sec">
              <span class="number" v-if="room_status == 'Insufficient Players'"> - </span>
              <span class="number" v-else>{{ seconds }}</span>
            </div>

        </div>
        <h6 class="text-muted text-danger  m-auto pt-4" v-if="text">{{text}}</h6>
    </div>


</template>

<script>
    export default {
        name: "TimerRoom",
        props: ['passTime_p','room_status'],
        data: function () {
            return {
                timer: "",
                interval: "",
                hours: 0,
                minutes: 0,
                seconds: 0,
                passTime: '',
                text:'',
            };
        },


        watch: {
            passTime_p: function () {
                this.passTime = this.passTime_p;
              this.interval = setInterval(() => {
                this.calcTime();
              }, 1000);
            },

        },

        mounted() {
            this.passTime = this.passTime_p;
            this.text = ''
            this.interval = setInterval(() => {
            this.calcTime();
            }, 1000);

        },
        methods: {
            calcTime() {
                if (this.passTime <= 0){
                    clearInterval(this.interval)
                    this.seconds = 0
                    this.$emit('end-time')
                    if(this.room_status=='Running'){
                        this.text = 'Room finished'
                    }
                }
                else if(this.passTime > 0){
                  this.hours = Math.floor((this.passTime % 86400) / 3600);
                  this.minutes = Math.floor((this.passTime % 3600) / 60);
                  this.seconds = Math.floor(this.passTime % 60);
                    this.passTime = this.passTime - 1

                }

            }

        }
    }
</script>

<style scoped lang="scss">


    .timer {
        font-size: 20px;
        color: #fff;
        text-align:center;
        margin-top: 50px;

        .day, .hour, .min, .sec ,.nbr{
            font-size: 22px;
            display: inline-block;
            font-weight: 300;
            text-align: center;
            .format {
                font-weight: 300;
                font-size: 14px;
                //@include margin-start(5);
                //display: inline-block;
                opacity: 0.8;
                width: 20px;
            }
        }
        .number{

            padding: 0 5px;
            border-radius: 5px;
            display: inline-block;
            width: 30px;
            text-align: center;
        }

    }
</style>