<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center mb-3">{{ $t('koth.hacktivity') }}</h4>

      <b-card-text v-if="isload">
        <simplebar style="max-height: 240px;padding-right: 12px;padding-left: 12px;" v-if="notifications.length">
          <ul class="list-unstyled chat-list">

            <li v-for="data of notifications"
                :key="data.id">
              <hr>

              <div class="media mb-2">
                <div class="media-left mr-2 my-auto">
                  <i class="fas fa-2x" :class="{
                                       'fa-chess-king king':data.type=='King Change',
                                       'fa-flag text-white':data.type=='Correct Flag Submission',
                                       'fa-laptop-code join':data.type=='Join Room',
                                       'fa-laptop-code text-white':data.type=='Machine Reset',
                                       'fa-laptop-code text-danger':data.type=='Leave Room',
                                    }"></i>
                </div>
                <div class="media-body overflow-hidden">
                  <div>
                    <span class="font-size-11 float-right">{{ data.time_diff }}</span>

                    <h5 class="font-size-14 mb-1">{{ data.type }}</h5>

                  </div>

                  <p class=" mb-0">{{ data.message }}</p>
                </div>

              </div>

            </li>
          </ul>
        </simplebar>
        <span class="text-muted row" v-else>
                 <p class="m-auto">{{ $t('validation.no_data') }}</p>
            </span>
      </b-card-text>
      <b-card-text v-else>
        <simplebar style="max-height: 240px;padding-right: 12px;padding-left: 12px;">
          <ul class="list-unstyled chat-list">

            <li v-for="index in 4"
                :key="index">
              <hr>

              <div class="media mb-2">
                <div class="media-left mr-2 my-auto">
                  <b-avatar/>
                </div>
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 mb-1">
                    <b-skeleton width="50%" class="mb-2"></b-skeleton>
                  </h5>
                  <p class=" mb-0">
                    <b-skeleton width="100%" class="mb-2"></b-skeleton>
                    <b-skeleton width="70%" class="mb-2"></b-skeleton>
                  </p>
                </div>
                <div class="font-size-11">
                  <b-skeleton width="20%" class="mb-2"></b-skeleton>
                </div>

              </div>

            </li>
          </ul>
        </simplebar>
      </b-card-text>


    </div>
  </div>
</template>

<script>

import simplebar from "simplebar-vue";

export default {
  name: "HacktivityKoth",
  components: {simplebar},
  props: ['notifications_p'],
  watch: {
    notifications_p: {
      immediate: true,
      handler() {
        if (this.notifications_p) {
          if (this.notifications_p.length > 1) this.notifications = this.notifications_p;
          else if (this.notifications_p.length == 1) this.notifications.unshift(this.notifications_p[0]);
        }
      }
    }
  },
  data() {
    return {
      isload: false,
      notifications: []
    }
  },
  created() {
    this.loadNotifications()

  },
  methods: {
    loadNotifications() {
      this.$koth
          .get('rooms/' + this.$route.params.id + '/hacktivity')
          .then(response => {
            console.log(response.data)
            this.notifications = response.data
            this.isload = true
          })
          .catch(error => {
            console.log(error)
          })
    },
  }
}
</script>

<style scoped>
.king {
  color: #ECBD34
}

.join {
  color: #34c38f
}
</style>